import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, signal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'

import { Observable } from 'rxjs'

import { ElementService, ProjectService } from '../../../../services'
import { StageUiStore } from '../../../../store/stage-ui.store'

/**
 * 元素对齐
 */
@Component({
  selector: 'ace-alignment',
  standalone: true,
  imports: [CommonModule, MatIconButton, MatIcon],
  template: `
    @for (align of alignList(); track $index) {
      <button
        (click)="align.fn()"
        class="hover:bg-primary-600 group flex h-5 w-5 items-center justify-center rounded border-none bg-transparent outline-none hover:bg-opacity-10 disabled:cursor-not-allowed disabled:bg-transparent"
        [disabled]="align?.disable && (align.disable | async)"
      >
        <mat-icon [svgIcon]="'editorup:' + align.icon" class="text-tertiary group-disabled:text-tertiary-300 h-3.5 min-h-3.5 w-3.5 min-w-3.5"></mat-icon>
      </button>
    }
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlignmentComponent {
  @HostBinding('class') class = 'flex items-center w-full flex-grow justify-between'
  uiStore = inject(StageUiStore)
  projectService = inject(ProjectService)
  elementService = inject(ElementService)

  elements = this.uiStore.selectedRootElements
  alignDistributeStatus = computed(() => this.elementService.alignDistributeStatus())

  /**
   * 计算是否可以进行水平间距分布
   * 计算规则：
   * 1. 选中元素为2个或者0个时，不可进行水平间距分布
   * 2. 选中元素为1个时，不可进行水平间距分布
   * 3. 选中元素大于2个时，计算每个元素之间的间距是否相等
   */
  distributeHorizontalSpacingDisable = computed(() => !this.alignDistributeStatus().distributeHorizontal)

  /**
   * 计算是否可以进行垂直间距分布
   * 计算规则：
   * 1. 选中元素为2个或者0个时，不可进行垂直间距分布
   * 2. 选中元素为1个时，不可进行垂直间距分布
   * 3. 选中元素大于2个时，计算每个元素之间的间距是否相等
   */
  distributeVerticalSpacingDisable = computed(() => !this.alignDistributeStatus().distributeVertical)

  /**
   * 计算是否可以进行左对齐
   */
  alignLeftDisable = computed(() => !this.alignDistributeStatus().alignLeft)

  /**
   * 计算是否可以进行右对齐
   */
  alignRightDisable = computed(() => !this.alignDistributeStatus().alignRight)

  /**
   * 计算是否可以进行顶对齐
   */
  alignTopDisable = computed(() => !this.alignDistributeStatus().alignTop)

  /**
   * 计算是否可以进行底对齐
   */
  alignBottomDisable = computed(() => !this.alignDistributeStatus().alignBottom)

  /**
   * 计算是否可以进行垂直对齐
   */
  alignVerticalDisable = computed(() => !this.alignDistributeStatus().alignVertical)

  /**
   * 计算是否可以进行水平对齐
   */
  alignHorizontallyDisable = computed(() => !this.alignDistributeStatus().alignHorizontal)

  /**
   * 对齐操作列表
   */
  alignList = signal<
    {
      icon: string
      fn: () => void
      disable?: Observable<boolean>
    }[]
  >([
    // 左对齐
    { icon: 'align-left', disable: toObservable(this.alignLeftDisable), fn: () => this.elementService.alignLeft(this.elements()) },
    // 水平对齐
    {
      icon: 'align-horizontally',
      fn: () => this.elementService.alignHorizontal(this.elements()),
      disable: toObservable(this.alignHorizontallyDisable)
    },
    // 右对齐
    { icon: 'align-right', fn: () => this.elementService.alignRight(this.elements()), disable: toObservable(this.alignRightDisable) },
    // 顶对齐
    { icon: 'align-top', fn: () => this.elementService.alignTop(this.elements()), disable: toObservable(this.alignTopDisable) },
    // 底对齐
    {
      icon: 'align-bottom',
      fn: () => this.elementService.alignBottom(this.elements()),
      disable: toObservable(this.alignBottomDisable)
    },
    // 垂直对齐
    {
      icon: 'align-vertical',
      fn: () => this.elementService.alignVertical(this.elements()),
      disable: toObservable(this.alignVerticalDisable)
    },
    // 水平间距分布
    {
      icon: 'distribute-horizontal-spacing',
      disable: toObservable(this.distributeHorizontalSpacingDisable),
      fn: () => this.elementService.distributeHorizontalSpacing(this.elements())
    },
    // 垂直间距分布
    {
      icon: 'distribute-vertical-spacing',
      disable: toObservable(this.distributeVerticalSpacingDisable),
      fn: () => this.elementService.distributeVerticalSpacing(this.elements())
    }
  ])
}
